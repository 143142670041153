.custom-tooltip {
  margin-top: 0 !important;
  border-radius: 20px !important;
  font-family: $font-family !important;
  background: $color-button-bg-blue !important;
  color: $color-button-bg-blue !important;
  background-color: $color-button-bg-blue !important;

  &.place-top:after {
    border-top-color: $color-button-bg-blue !important;
    border-top-style: solid !important;
    border-top-width: 6px !important;
  }

  p {
    margin: 4px 0 0 0;
    color: $color-button-white;
    text-transform: uppercase;
  }

  ul {
    margin: 10px 0 5px 0;
    color: $color-button-white;
  }
}
