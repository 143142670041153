.container {
  margin: 0;
  // padding: 0;

  @media screen and (min-width: 768px) {
    width: 100%;
    max-width: 1920px;
  }
}

.container__wrap {
  // padding-left: 72px;
  min-width: 920px;
  min-height: 100vh;
  background: $color-background;

  .navigation-container {
    padding-left: 72px;
  }

  .container {
    padding-left: 72px;

    &.dashboard {
      padding-left: 57px;
    }
  }
}
