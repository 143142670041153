.dropzone {
  width: 100%;
  display: flex;
  position: relative;
  cursor: pointer;

  &.dropzone--multiple {
    min-height: 200px;
    background-color: $color-line-dark;

    .dropzone__input {
      min-height: 200px;
    }
  }
}

.dropzone__input {
  width: 100%;
  height: 100%;
  min-height: 298px;
  display: flex;
  position: absolute !important;
  cursor: pointer;

  &:hover, &:active, &:visited, &:focus {
    outline: none;
    border: none;
  }
}

.dropzone__drop-here {
  margin: auto;
  color: $color-additional;
}

.dropzone__imgs-wrapper {
  margin: 15px 0;
  padding: 15px 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: #FFFFFF;
  
  border-top: 3px dashed $color-background-sidebar;
  border-bottom: 3px dashed $color-background-sidebar;
}

.dropzone__img {
  margin: 0 15px 15px 15px;
  padding-left: 15px;
  width: 100%;
  height: 37px;
  position: relative;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
  display : flex;
  align-items : center;
}

.dropzone__img-name {
  margin-left: 15px;
  position: absolute;
  display: inline-block;
  font-size: 13px;
  z-index: 10;
  width: 100%;
  line-height: 13px;
  margin: 0;
  color: $color-font-active;
}

.dropzone__img-delete {
  position: absolute;
  display: inline-block;
  right: 0;
  z-index: 10;
  cursor: pointer;
  background: transparent;
  font-size: 9px;
  border: 1px solid white;
  text-transform: uppercase;
  padding: 2px 7px;
  line-height: 10px;

  &:hover, &:active, &:visited, &:focus {
    outline: none;
    border: none;
  }
}
