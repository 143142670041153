.modal-content {
  border-radius: 43px;
}

.modal-dialog {
  max-width: 834px;

}

.modal-header {
  padding: 47px 72px 24px 72px;
  border: 0;

  .close span {
    display: none;
  }

  .modal-title {
    position: relative;
    width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: $color-font-active;
  }

  .close-btn {
    position: absolute;
    width: 52px;
    height: 52px;
    right: 0;
    margin-top: -15px;
    margin-right: -60px;
    border: 0;
    background: transparent;
    
    svg {
      width: 100%;
      height: auto;
    }
  }
}

.modal-body {
  padding: 0px 57px 47px 57px;

  .modal-sub-title {
    margin-bottom: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    color: $color-font-active;
  }
}
