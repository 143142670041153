.form {
  display: flex;
  flex-wrap: wrap;

  input, textarea {
    width: 100%;
    padding: 10px 12px;
    font-size: 13px;
    height: 37px;
    border: 1px solid $color-line;
    box-sizing: border-box;
    border-radius: 4px;
    color: $color-font;

    &:focus, &:active {
      outline: none;
      border-color: $color-font;
    }
  }

  textarea {
    min-height: 85px;
  }

}

.form__form-group {
  margin-bottom: 33px;
  width: 100%;
  position: relative;
}

.form__form-group-field {
  width: 100%;
  display: flex;
  margin: auto;
}

.form__form-group-label {
  margin-bottom: 4px;
  display: inline-block;
  color: $color-font;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
}

.form__form-group-input-wrap {
  width: 100%;
  &:focus, &:active {
    outline: none;
    border-color: $color-font;
  }
}

.form__form-group-datepicker {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;

  &.react-datepicker-ignore-onclickoutside {
    border-right-color: $color-font;
  }
}

.form__form-group-error {
  font-size: 10px;
  line-height: 13px;
  color: #ad4444;
  margin-bottom: -5px;
  display: block;
  margin-top: 5px;
}

.form__form-group-input-wrap--error-above {

  .form__form-group-error {
    position: absolute;
    margin: 0;
    right: 0;
    top: 0;
    padding: 5px 10px;
    background: #ffbcbc;
    border-radius: 3px;

    &:after {
      content: '';
      position: absolute;
      right: 10px;
      bottom: -8px;
      border: 4px solid transparent;
      border-top: 4px solid #ffbcbc;
    }
  }
}

/* select */
.select-inside__indicator-separator {
  display: none;
}

.select-inside__control {
  border: 1px solid $color-line !important;
}

.select-inside__single-value {
  font-size: 13px;
  color: $color-font !important;
  max-width: 85% !important;
}

.select-inside__menu {
  margin: 0 !important;
  color: $color-font !important;
}

.select-inside__value-container--is-multi {
  -webkit-flex-wrap: none !important;
  -ms-flex-wrap: none !important;
  flex-wrap: none !important;
}

/* files upload */
.file-list {
  margin-bottom: 33px;

  .file-item {
    position: relative;
    margin-bottom: 12px;
    height: 37px;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 4px;
    list-style-type: none;
    display : flex;
    align-items : center;

    .file-link {
      margin-left: 15px;
      padding: 0;
      display: inline-block;
      border: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      color: $color-font-active;
    }

    .delete-file {
      position: absolute;
      display: inline-block;
      width: 35px;
      height: 36px;
      padding: 0;
      border: 0;
      background: transparent;
      right: 0;

      &:hover, &:active, &:visited, &:focus {
        outline: none;
        border: none;
      }
    }

  }
}

.form__form-group-button {
  padding: 6px;
  height: 37px;
  cursor: pointer;
  transition: all 0.3s;
  background: $color-line;
  border: 1px solid $color-line;

  svg {
    fill: $color-additional;
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }

  &.active {
    background: $color-accent;
    border: 1px solid $color-accent;

    svg {
      fill: #ffffff;
    }
  }
}

.password-field {
  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.form__form-group-icon {
  padding: 8px;
  height: 37px;

  background: white;
  border: 1px solid $color-line;
  border-left: 0;

  svg {
    fill: $color-line-dark;
    width: 20px;
    height: 20px;
    transition: all 0.3s;
  }
}
