.material-table__wrap {
  
  .material-table{
    border-collapse: separate;
    border-spacing: 0 7px;
  }

  th {
    padding: 0 0 0 10px;
  }

  .table-row {
    min-height: 50px;

    &.active {
      .cell-content-text, .first, .last {
        border-color: $color-line-active !important;
      }
    }
  }

  .table-row::before {
    position: absolute;
    margin-top: 7px;
    left: -3px;
    content: "|";
    width: 3px;
    height: 36px;
    color:transparent;
    background-color:transparent;
  }

  .table-row{
    &.red::before {
      color: $color-row-before-red;
      background-color: $color-row-before-red;
    }
    &.green::before {
      color: $color-row-before-green;
      background-color: $color-row-before-green;
    }
    &.gray::before {
      color: $color-row-before-gray;
      background-color: $color-row-before-gray;
    }
    &.orange::before {
      color: $color-row-before-orange;
      background-color: $color-row-before-orange;
    }
  }

  .cell-content-text div, .MuiTableHead-root th {
    font-family: $font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: $color-font-table-head;
  }

  .MuiTableHead-root th {
    font-weight: 500;
  }

  .MuiTableBody-root {
    .cell-content-operations, .cell-content-text {
      padding: 0;
      height: 50px;
    }

    .cell-content-text {
      padding-left: 10px;
      border-bottom: 2px solid $color-line;
      border-top: 2px solid $color-line;
    }

    .last {
      border-right: 2px solid $color-line;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    .first {
      border-left: 2px solid $color-line;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }

  .MuiTableCell-root {
    border: 0;
  }

  .cell-content-toggle {
    border: 2px solid $color-line;
    border-radius: 8px;
    background-color: $color-line;

    .MuiTableCell-head {
      border-bottom: 2px solid $color-line-dark;
    }
  }
}

.tablePagination {
  button {
    &:hover,  &:active,  &:visited, &:focus {
      box-shadow: none !important;
      outline: none;
      border: none;
    }
  }

  p {
    font-family: $font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $color-font-table-head;
  }

  .MuiSelect-selectMenu {
    font-family: $font-family;
    color: $color-font-table-head;
  }
}

.MuiMenu-list li {
  font-family: $font-family !important;
  color: $color-font-table-head !important;
}
