@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/timeline/main.css';
@import '~@fullcalendar/resource-timeline/main.css';

.fc {
  .fc-center h2, .fc-widget-header, .fc-cell-text {
    font-family: $font-family !important;
    color: $color-font !important;
  }

  /** Header */
  .fc-center h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px !important;
    line-height: 20px;
    text-transform: uppercase;
  }

  .fc-widget-header {
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
  }

  .fc-resource-area {
    font-size: 18px !important;
    line-height: 22px;
  }

  .fc-time-area {
    font-size: 14px  !important;
    line-height: 22px;
  }
  
  .fc-button {
    padding: 9px 16px 8px 16px;
    height: 36px;
    background: $color-button-bg-blue;
    color: $color-button-white;
    border-radius: 21px;
    border: 0 !important;
    vertical-align: middle;
    position: relative;
    float: right;

    font-family: $font-family;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    text-transform: uppercase;

    .fc-icon {
      line-height: 16px !important;
    }

    &.fc-button-active {
      background-color: $color-button-active-bg-blue !important;
    }

    &:hover, &:active, &:visited, &:focus {
      background-color: $color-button-active-bg-blue !important;
      outline: none;
      border: none;
      box-shadow: none !important;
    }
  }

  .fc-button:disabled {
    opacity: 1;
    background-color: $color-button-active-bg-blue !important;
  }

  .fc-right {
    margin-right: 10px;

    .fc-button {
      margin-left: 10px;
    }
  }
  .fc-left {
    margin-left: 10px;

    .fc-button-group {
      margin: 0 10px 0 0;
    }
  }

  /** Body */
  .fc-body {
      .fc-resource-area {

        .fc-cell-text {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 26px;
        }
      }

      .fc-time-area {
        .fc-time {
          display: none;
        }
        .fc-title {
          font-family: $font-family !important;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          
          color: #FFFFFF;
        }
      }
  }
}
