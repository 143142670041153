.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  height: 100%;
  width: 60px;
  background: $color-background-sidebar;

  a {
    display: block;
  }
}
// svg 20 legyen a szélesség
.sidebar__content {
  height: 100%;

  .sidebar__logo {
    margin: 8px 14px 0px 18px;
  }

  .sidebar__block {
    margin-top: 11px;
    padding: 0 10px;
  
    .sidebar__link {
      margin-bottom: 17px;
      padding: 10px;
      height: auto;
      width: 60px;
      position: relative;
      cursor: pointer;
      background: transparent;
      border: none;

      svg {
        width: 52%;
        height: auto;
      }
    }
    
    .sidebar__link-active {
      background: rgba(255, 255, 255, 0.2);
      border-radius: 8px;
    }
  }
  
  .sidebar__block_logout {
    position: absolute;
    bottom: 20px;
    padding-left: 18px;
    
    .sidebar__link {
      list-style-type: none;

      svg {
        width: 80%;
        height: auto;
      }
    }
  }
}

.sidebar__wrapper--desktop {
  display: block;
  height: 100%;
}
