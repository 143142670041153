$font-family: DIN Next LT Pro;

$color-background: #FFFFFF;
$color-background-sidebar: #0A8DD7;

$color-font-active: #0A8DD7;
$color-font: #9DA4AD;

$color-font-table-head: #8B929C;

$color-line: #EBECF0;
$color-line-dark: #D1D8DC;
$color-line-active: #0A8DD7;

$color-button-bg-blue: #18A0FB;
$color-button-active-bg-blue: #0B78B6;
$color-button-white: #FFFFFF;

$color-row-before-red: #D94343;
$color-row-before-green: #00DB8C;
$color-row-before-gray: #8B929C;
$color-row-before-orange: orange;

$color-accent: #0A8DD7;
$color-accent-hover: darken($color-accent, 10%);
$color-additional: #999999;
$color-additional-hover: darken($color-additional, 10%);

$color-yellow: #f6da6e;
$color-yellow-hover: darken($color-yellow, 10%);

$color-violet: #c88ffa;

$color-red: #ff4861;
$color-red-hover: darken($color-red, 10%);

$color-blue: #0A8DD7;
$color-blue-hover: darken($color-blue, 10%);

$color-gray: #787985;
$color-light-gray: #9DA4AD;

$color-green: #b8e986;
$color-green-hover: darken($color-green, 10%);
