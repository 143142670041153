.container-list {
  border-right: 2px solid $color-line;
}

.card {
  width: 100%;
  height: 100%;
  border: none;
}

.card-body {
  padding: 18px 0 30px 0;
  height: 100%;
}

.card-title {
  padding: 8px 0 8px 0;

  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    text-transform: uppercase;
    color: $color-font;
    
    span {
      font-weight: 500;
      color: $color-font-active;
    }
  }


}
