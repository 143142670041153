.navigation-container {
  padding: 0 15px;
}

.navigation {
  padding: 29px 0px 29px 36px;
  width: 100%;
  border-bottom: 2px solid $color-line;
  box-sizing: border-box;

/*   .left-navigation {
    
  } */

  .nav-tab {
    margin: 0 60px 0 0;
    font-size: 15px;
    line-height: 24px;
    color: $color-font;
    text-transform: uppercase;
    
    @media only screen and (max-width: 1500px) {
        margin: 0 20px 0 0;
    }

    &.active {
      color: $color-font-active;
      border-bottom: 4px solid $color-font-active;
    }
  }
}

.tracking-tabs {
  .nav-tabs {
    border-bottom: 0;
  }

  .nav-link {
    margin: 8px 16px 20px 16px;
    padding: 0;
    font-size: 15px;
    line-height: 24px;
    color: $color-font;
    outline: none;
    border: 0;
    box-shadow: none !important;

    &:hover,  &:active,  &:visited, &:focus {
      border: 0;
      box-shadow: none !important;
      outline: none;
      color: $color-font;
      cursor: pointer;
    }

    &.active {
      border: 0;
      color: $color-font-active;
      border-bottom: 4px solid $color-font-active;
    }
  }
}
