.account {
  height: 100vh;
  width: 100%;
  min-height: 100vh;
  display: flex;
  overflow-y: auto;
  background: #ffffff;
}

.account__wrapper {
  margin: 50px auto;
}

.account__card {
  background: #ffffff;
  background-color: #ffffff;
  padding: 50px 60px;
  max-width: 520px;
  width: 100%;
}

.account__btns {
  display: flex;
  width: calc(100% + 10px);

  a {
    margin: 10px 60px 20px 0;
    white-space: nowrap;
    background-color: $color-light-gray;
    border: 0;
  }

  button {
    margin: 10px 0 20px 0;
    white-space: nowrap;
  }

  .btn-primary {
    background-color: $color-button-bg-blue !important;
    border: 0;

    &:hover, &:focus, &:active, &:active:focus {
      opacity: unset;
      box-shadow: none !important;
    }

    &:hover {
      background-color: $color-button-active-bg-blue !important;
    }
  }
}

.account__btn {
  width: 100%;
  margin-right: 0;
  border-radius: 50px;
  text-transform: uppercase;
}

.account__head {
  margin-bottom: 95px;
}

.account__logo {
  svg {
    display: flex;
    margin: 0 auto;
    height: 170px;
    width: 249px;
  }
}

@media screen and (max-width: 520px) {

  .account__card {
    padding: 35px 30px;
  }
}

@media screen and (max-width: 425px) {

  .account__btns {
    flex-wrap: wrap;
  }
}
