.alert {

  .alert-msg{
    position: relative;
    display: flex;
  }

  .alert-close-btn {
    position: absolute;
    right: 0;
    top: calc(50% - 12px);
    border: 0;
    background: transparent;
    padding: 0;

    svg {
      width: 24px;
      height: 24px;
    }

    &:focus, &:active, &:active:focus {
      outline: none;
    }
  }

  &.alert-info {
    .alert-msg .alert-close-btn svg rect {
      fill: #0c5460;
    }
  }

  &.alert-success {
    .alert-msg .alert-close-btn svg rect {
      fill: #155724;
    }
  }

  &.alert-warning {
    .alert-msg .alert-close-btn svg rect {
      fill: #856404;
    }
  }

  &.alert-danger {
    .alert-msg .alert-close-btn svg rect {
      fill: #721c24;
    }
  }
}
