.dashboard {
  padding-left: 0;
  padding-right: 0;

  .dashboard__map {

    .marker-label {
      padding-top: 14px;
      height: 40px;
      width: 124px;
      font-family: DIN Next LT Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #FFFFFF;
      background: #5F5F5F;
      border-radius: 6px;
    }

    .info-box-wrap {
      position: absolute;
      bottom: 20px;
      width: 100%;
      padding-left: 15%;
      padding-right: 15%;

      .info-box {
        background:#fff;
        border: 2px solid #0A8DD7;
        border-radius: 6px;

        .info-box-bg {
          background-color: #0A8DD7;
        }

        .table {
          background:#fff;
          margin-bottom: 0;
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;

          .thead-blue {
            tr {
              border-color: #0A8DD7;
              th {
                color: #fff;
                background-color: #0A8DD7;
                border-color: #0A8DD7;
              }
            }
          }

          thead {
            font-size: 12px;
            th {
              border-bottom: unset;
            }
          }

          tbody {
            font-size: 11px;
            td {
              .point {
                display: inline-block;
                border-radius: 50%;
                width: 11px;
                height: 11px;
                vertical-align: middle;

                &.point-false {
                  background-color: #DB7878;
                }

                &.point-true {
                  background-color: #00DB8C;
                }
              }
            }
          }

          th, td {
            padding: 0.5rem;
            border-top: unset;
            text-align: center; 
          }
        }
      }
    }
    
    .info-box-text-wrap {
      height:40px !important;
      width:120px;
      float:left;
      overflow: hidden;
    }
  }
}
