.button-container {
  height: 36px;
}

.new-item {
  padding: 9px 28px 8px 16px;
  height: 36px;
  background: $color-button-bg-blue;
  color: $color-button-white;
  border-radius: 21px;
  border: 0;
  vertical-align: middle;
  position: relative;
  float: right;

  &:hover,  &:active,  &:visited, &:focus {
    box-shadow: none !important;
    outline: none;
    border: none;
  }

  &:hover {
    background-color: $color-button-active-bg-blue !important;
  }



  .button-text {
    margin-right: 15px;
    font-family: $font-family;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    text-transform: uppercase;
  }

  svg {
    width: 15%;
    height: auto;
    position: absolute;
    top: 9px;
  }
}

.cell-content-operations{
  button {
    width: 36px;
    height: 36px;
    margin-left: 2px;
    padding: 0;
    border: 0;
    background: transparent;

    &:hover, &:active, &:visited, &:focus {
      outline: none;
      border: none;
    }
  
    svg {
      width: 68%;
      height: auto;

      &.track-active {
        path {
          fill: $color-font-active;
        }
      }
    }
  }
}

.form__button-toolbar {
  width: 100%;
  height: 36px;
  position: relative;

  .submit-button{
    position: absolute;
    right: 0;
    width: 120px;
    height: 36px;
    font-size: 15px;
    font-style: normal;
    font-weight: bold;
    line-height: 20px;
    color: #FFFFFF;
    background: #18A0FB !important;
    border: none !important;
    border-radius: 50px;
    text-transform: uppercase;

    &:hover,  &:active,  &:visited, &:focus {
      box-shadow: none !important;
    }

    &:hover {
      background-color: $color-button-active-bg-blue !important;
    }
  }
}
