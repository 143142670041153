.rc-notification {
  position: fixed;
  z-index: 100;
}

.rc-notification {
  left: auto !important;
  top: 40px !important;
  height: 0;

  & > span {
    height: 0;
    display: block;
  }
}

.rc-notification-notice {
  display: block;
  width: auto;
  line-height: 1.5;
  vertical-align: middle;
  position: relative;
}

.rc-notification-notice-close {
  position: absolute;
  cursor: pointer;
  outline: none;
  font-weight: 700;
  line-height: 1;
  opacity: .2;
  text-decoration: none;
}

.rc-notification-notice-close-x:after {
  content: '×';
  color: white !important;
}

.rc-notification-notice-close {
  right: 35px;
  top: 0;
  font-size: 50px;
  line-height: 1;
  font-weight: 300;
  color: #646777;
  text-shadow: none;
  opacity: 0.2;

  &:hover {
    opacity: 0.5;
    color: #646777;
  }
}

.notification {
  max-width: 400px;
  width: calc(100% - 50px);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.07);
  background: $color-button-bg-blue;
  padding: 20px 40px 30px 25px;
  position: relative;
  margin: 10px 25px;
  top: 0;
  left: 0;
  border-radius: 20px !important;

  &.notification--image {
    height: 106px;
    overflow: hidden;
  }

  &.notification--full-wide {
    max-width: 100vw;
    width: 100vw;
    margin: 0;
    padding: 20px 40px 20px 25px;
  }

  &.notification--primary {
    background: $color-blue;

    .notification__message, .notification__title {
      color: white;
    }
  }

  &.notification--success {
    background: $color-accent;

    .notification__message, .notification__title {
      color: white;
    }
  }

  &.notification--warning {
    background: $color-yellow;

    .notification__message, .notification__title {
      color: white;
    }
  }

  &.notification--danger {
    background: $color-red;

    .notification__message, .notification__title {
      color: white;
    }
  }
}

.notification__message {
  margin-top: 0;
  font-size: 13px;
  color: $color-button-white;
  font-family: $font-family !important;
}

.notification__title {
  margin-bottom: 8px;
  color: $color-button-white;
  font-family: $font-family !important;
}

.notification__image {
  position: absolute;
  height: 106px;
  width: 106px;
  top: 0;
  left: 0;

  & ~ * {
    padding-left: 106px;
  }
}

.sidebar.sidebar--collapse + .container__wrap {

  .notifications-tl, .notifications-bl {
    left: 0 !important;
  }
}
